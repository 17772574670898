@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  box-sizing: border-box;
  height: 100%;

  @apply bg-content-spring-wood;
}

body,
#root {
  height: 100vh;
  width: 100%;
  display: flex;
  overflow: auto;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

*::selection {
  @apply text-neutral-white;
  @apply bg-interaction;
}

p {
  @apply text-neutral-soil;
}

a {
  @apply text-interaction;
  outline: none;
}
