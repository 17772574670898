.DayPicker {
  width: 100%;
  margin: 0 auto;
}

.DayPickerNavigation_button {
  @apply border-none;
}

.CalendarDay__today {
  @apply bg-content-mist;
}

.CalendarDay {
  @apply rounded-full;
  padding: 0;
}

.CalendarDay,
.CalendarDay:hover {
  border: 0;
}

.CalendarDay__outside {
  @apply text-neutral-stone;
  @apply pointer-events-none;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  border: 0;
  @apply rounded-none;
  @apply text-neutral-soil;
  @apply bg-secondary;
}

.CalendarDay__selected_span {
  @apply bg-secondary;
  @apply rounded-none;
  @apply text-neutral-soil;
}

.CalendarDay__selected_span:hover {
  @apply bg-secondary-200;
  @apply text-neutral-soil;
}

.CalendarDay__selected,
.CalendarDay__selected:hover {
  border: 0;
  @apply rounded-none;
  @apply bg-secondary;
  @apply text-neutral-soil;
}


.CalendarDay__selected_start,
.CalendarDay__selected_start:hover {
  @apply rounded-l-full;
}


.CalendarDay__selected_end,
.CalendarDay__selected_end:hover {
  @apply rounded-r-full;
}

.CalendarDay__selected:hover {
  @apply bg-secondary-200;
}


