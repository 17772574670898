.ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}
.ql-editor li.ql-direction-rtl::before {
  margin-left: 0.3em;
  margin-right: -1.5em;
}
.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 0em;
}
.ql-editor ol li.ql-direction-rtl,
.ql-editor ul li.ql-direction-rtl {
  padding-right: 0em;
}
.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}
.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}
.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 1.5em;
}
.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 1.5em;
}
.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 3em;
}
.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 3em;
}
.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}
.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}
.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}
.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}
.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}
.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}
.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 6em;
}
.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 6em;
}
.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}
.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}
.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}
.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}
.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}
.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}
.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 9em;
}
.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 9em;
}
.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}
.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}
.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}
.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}
.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}
.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}
.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 12em;
}
.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 12em;
}
.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}
.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}
.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}
.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}
.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}
.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}
